function daysBetweenDates(dateString) {
    const ONE_DAY = 1000 * 60 * 60 * 24; // in Millisekunden

    const dateParts = dateString.split('.');
    const day = parseInt(dateParts[0]);
    const month = parseInt(dateParts[1]) - 1; // Monate starten bei 0 (Januar)
    const year = parseInt(dateParts[2]);

    const startDate = new Date(year, month, day);
    const currentDate = new Date();

    // Differenz in Millisekunden zwischen den beiden Daten berechnen
    const differenceMs = currentDate.getTime() - startDate.getTime();

    // Anzahl der Tage als Ganzzahl berechnen und zurückgeben
    const days = Math.floor(differenceMs / ONE_DAY);
    return days;
  }

  // Beispielaufruf
  const days = daysBetweenDates("01.10.2003");

  if(document.querySelector('#placeholderFoundingDays')) {
    document.querySelector('#placeholderFoundingDays').textContent = days
}
