if(document.querySelector(".tile--group")) {
    // Speichern Sie den Container und die Kacheln darin
    const container = document.querySelector(".tile--group .group--inner");
    const tiles = container.querySelectorAll(".tile");

    // Funktion zum Rendern der Kacheln basierend auf der Viewport-Breite
    function renderTiles() {
        // Breite des Viewports abrufen
        const width = window.innerWidth;

        // Entscheiden, wie viele Spalten gerendert werden sollen
        let numColumns = 1;
        if (width >= 1250) {
        numColumns = 3;
        } else if (width >= 768) {
        numColumns = 2;
        }

        // Leere den Container
        container.innerHTML = "";

        // Erstellen Sie Spaltencontainer
        for (let i = 0; i < numColumns; i++) {
        const column = document.createElement("div");
        column.classList.add("column");
        container.appendChild(column);
        }

        // Fügen Sie die Kacheln den Spalten hinzu
        let columnIndex = 0;
        tiles.forEach((tile) => {
        const column = container.querySelectorAll(".column")[columnIndex];
        column.appendChild(tile);

        // Wenn wir die letzte Spalte erreichen, setzen wir den Spalten-Index zurück
        columnIndex++;
        if (columnIndex >= numColumns) {
            columnIndex = 0;
        }
        });
    }

    // Rendern Sie die Kacheln beim Laden der Seite und bei jedem Ändern der Größe des Viewports neu
    window.addEventListener("load", renderTiles);
    window.addEventListener("resize", renderTiles);
}
