const toggleMenu = document.querySelector('.toggle--burger')
const navigationDefault = document.querySelector('.navigation--header')
const navPoint = document.querySelectorAll('.navigation--header a')

toggleMenu.addEventListener('click', event => {
    const menu = event.target
    menu.closest('.toggle--burger').classList.toggle('active')
    navigationDefault.classList.toggle('active')
})

navPoint.forEach(item => {
    item.addEventListener('click', event => {
        toggleMenu.classList.remove('active')
        navigationDefault.classList.remove('active')
    })
})

if(window.location.pathname == '/') {
    document.querySelector('footer .navigation--listitem').classList.add('current')
}
